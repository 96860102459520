import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadPercentage: '连接中',
  },
  mutations: {
    loadingState(state, event) {
      if (event.lengthComputable) {
        state.loadPercentage = Math.round((event.loaded * 100) / event.total) + '%';
      } else {
        state.loadPercentage = '即将完成'
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
